import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../BuildingBlocks/Button";

const LogoutButton = () => {
  const { logout, isAuthenticated } = useAuth0();
  if (!isAuthenticated) return null;
  return (
    <Button onClick={() => logout({ returnTo: window.location.origin })}>
      Abmelden
    </Button>
  );
};

export default LogoutButton;
