import React from "react";

function Button({ children, onClick }) {
  return (
    <div
      onClick={onClick}
      className="px-2 py-1.5 bg-1 inline-block cursor-pointer rounded-md text-white"
    >
      {children}
    </div>
  );
}

export default Button;
