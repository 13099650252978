import React, { useEffect } from "react";
import Layout from "../layouts/Layout";
import LogInButton from "../components/Authentication/LogInButton";
import LogoutButton from "../components/Authentication/LogOutButton";
import UserProfile from "../components/Authentication/UserProfile";
import { useAuth0 } from "@auth0/auth0-react";

function Profil(props) {
  const { getAccessTokenSilently, user } = useAuth0();
  useEffect(() => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log(JSON.stringify(user));
        const sub = await user.sub;
        const response = await fetch(
          `https://dev-3otjc9he.eu.auth0.com/api/v2/users/${sub}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(await response.json());
      } catch (e) {
        console.log(e);
      }
    })();
  }, [getAccessTokenSilently]);

  return (
    <Layout>
      <div className="container">
        <LogInButton />
        <LogoutButton />
        <UserProfile />
      </div>
    </Layout>
  );
}

export default Profil;
