import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../BuildingBlocks/Button";

const LogInButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  if (isAuthenticated) return null;
  return <Button onClick={() => loginWithRedirect()}>Anmelden</Button>;
};

export default LogInButton;
